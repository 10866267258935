<template>
  <div class="box" style="background-color: #f8fcff;">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>一键报废</span>
        <el-steps class="steps-wrapper" align-center :active="active">
          <el-step description="填写车主信息" icon="el-icon-s-help"></el-step>
          <el-step description="填写车辆信息" icon="el-icon-s-help"></el-step>
          <el-step description="上传相关图片" icon="el-icon-s-help"></el-step>
          <el-step description="提交成功" icon="el-icon-s-help"></el-step>
        </el-steps>
      </div>
      <div class="box">
        <el-form ref="form" :model="info" label-width="100px">
          <el-form-item label="行驶证正面">
            <div style="display: flex;">
              <el-upload accept=".jpg,.jpeg" class="avatar-uploader" :action="url"
                :multiple="false" :show-file-list="false" :before-upload="beforeAvatarUpload">
                <img v-if="imagePath" :src="imagePath" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <div class="photo-body">
                <img src="/img/xingshi4.png" />
              </div>
              <div class="distinguish-btn">
                <el-button class="photo-btn" type="primary" @click="handleDistinguish">识别
                </el-button>
              </div>
            </div>
          </el-form-item>
          <el-form-item class="form-input" label="号牌号码">
            <el-input placeholder="请输入号牌号码" v-model="info.number"></el-input>
          </el-form-item>
          <el-form-item class="form-input" label="详细车型">
            <el-input placeholder="请输入详细车型" v-model="info.vehicle_type"></el-input>
          </el-form-item>
          <el-form-item class="form-input" label="所有人">
            <el-input placeholder="请输入所有人" v-model="info.name"></el-input>
          </el-form-item>
          <el-form-item class="form-input" label="住址">
            <el-input placeholder="请输入住址" v-model="info.address"></el-input>
          </el-form-item>
          <el-form-item class="form-input" label="品牌型号">
            <el-input placeholder="请输入品牌型号" v-model="info.model"></el-input>
          </el-form-item>
          <el-form-item class="form-input" label="车辆识别代号">
            <el-input placeholder="请输入车辆识别代号" v-model="info.vin"></el-input>
          </el-form-item>
          <el-form-item class="form-input" label="发动机号码">
            <el-input placeholder="请输入发动机号码" v-model="info.engine_no"></el-input>
          </el-form-item>
          <el-form-item class="form-input" label="注册日期">
            <el-input placeholder="请输入注册日期" v-model="info.register_date"></el-input>
          </el-form-item>
          <el-form-item class="form-input" label="发证日期">
            <el-input placeholder="请输入发证日期" v-model="info.issue_date"></el-input>
          </el-form-item>
          <el-form-item class="form-input" label="使用性质">
            <el-input placeholder="请输入使用性质" v-model="info.use_character"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button class="submit-btn" @click="onSubmit">
              确认无误下一步</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
import { scrapThree, uploadScrapImage } from "../../api/personalCenter";
import { ajax } from "@/utils/ajax";
export default {
  name: "fillInTheOrder",
  data() {
    return {
      active: 2,
      imageId: "",
      imagePath: "",
      info: {
        address: "",
        engine_no: "",
        id: "0",
        issue_date: "",
        model: "",
        name: "",
        number: "",
        register_date: "",
        use_character: "",
        vehicle_type: "",
        vin: "",
        url: "",
      },
    };
  },
  created() {
    this.imagePath = this.$route.query.imgUrl;
    this.imageId = this.$route.query.imgId;
    this.url = process.env.VUE_APP_API_BASE_URL + "api/";
  },
  methods: {
    async handleDistinguish() {
      if (this.imagePath) {
        const params = {
          Id: this.$route.query.id,
          FileId: this.imageId,
          FilePath: this.imagePath,
        };
        const res = await scrapThree(params);
        Object.keys(res.result).forEach((item) => {
          if (this.info[item] !== undefined) {
            this.info[item] = res.result[item];
          }
        });
      } else {
        this.$message.error("请上传行驶证正面照片！");
      }
    },
    async beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG 格式!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
        return false;
      }
      const fd = new FormData();
      fd.append("file", file);
      const res = await uploadScrapImage(fd);
      this.imageId = res.result.fileId;
      this.imagePath = res.result.httpPath;
      return false;
    },
    async onSubmit() {
      const keys = Object.keys(this.info);
      const hasValueKeys = keys
        .map((item) => this.info[item])
        .filter((item) => item);
      this.info.RequreCarId = this.$route.query.id;
      var that = this;
      ajax.post("RequreCar/ScrapThreeSave", that.info, function (res) {
        if (res.result.success) {
          window.sessionStorage.setItem("firstScrap", JSON.stringify({}));
          that.$router.push({
            path: "/scrap/fourthScrap",
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.box-card {
  width: 1200px; /* no */
  margin: 20px auto; /* no */
}
.clearfix {
  font-size: 18px; /* no */
  padding: 0 0 20px 0; /* no */
}
.photo-body {
  width: 300px; /* no */
  height: 200px; /* no */
  margin-left: 20px; /* no */
}
.photo-btn {
  background: #24a2aa;
  color: white;
}
.form-input {
  width: 35%;
}
.submit-btn {
  background: #24a2aa;
  color: white;
  width: 180px; /* no */
  margin-right: 20px; /* no */
}
/deep/.el-radio__input.is-checked .el-radio__inner {
  border-color: #24a2aa;
  background: #24a2aa;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9; /* no */
  border-radius: 6px; /* no */
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px; /* no */
  color: #8c939d;
  width: 178px; /* no */
  height: 178px; /* no */
  line-height: 178px; /* no */
  text-align: center;
}

.avatar {
  width: 178px; /* no */
  height: 178px; /* no */
  display: block;
}

.distinguish-btn {
  margin-left: 30px; /* no */
}

/deep/.el-upload {
  background-color: #eeeeee;
  width: 300px; /* no */
  height: 201.79px; /* no */

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.steps-wrapper {
  width: 500px; /* no */
  float: right;
  /deep/.is-finish {
    color: #24a2aa !important;
    border-color: #24a2aa;
  }
}
</style>
